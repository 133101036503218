import { render, staticRenderFns } from "./simpleCropper.vue?vue&type=template&id=c61c44f6&scoped=true&"
import script from "./simpleCropper.vue?vue&type=script&lang=js&"
export * from "./simpleCropper.vue?vue&type=script&lang=js&"
import style0 from "./simpleCropper.vue?vue&type=style&index=0&id=c61c44f6&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c61c44f6",
  null
  
)

export default component.exports