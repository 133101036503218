<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-04-29 14:57:34
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-03 14:18:21
-->
<template>
  <div class="content">
    <div class="item">
      <div class="itemLeft"><span>工号</span><span class="red">*</span></div>
      <div class="itemRight">
        <span>{{form.empno}}</span>
      </div>
    </div>
    <div class="change_item">
        <div class="ci_left">
          <span>开户银行</span><span class="red">*</span>
        </div>
        <div class="ci_right">
          <input v-model="form.bankname"
                 type="text"
                 @click="changeBank"
                 readonly
                 placeholder="请选择开户银行" />
          <md-selector v-model="isSelectorShow"
                       :data="bankList"
                       :default-value="form.bankcode"
                       title="--请选择开户行--"
                       max-height="320px"
                       @choose="onSelectorChoose"></md-selector>
        </div>
      </div>
    <div class="item">
      <div class="itemLeft"><span>支行</span><span class="red">*</span></div>
      <div class="itemRight">
        <input v-model="form.bankzh"
               type="text"
               placeholder="请输入支行" />
      </div>
    </div>
    <div class="item">
      <div class="itemLeft"><span>银行卡号</span><span class="red">*</span></div>
      <div class="itemRight">
        <input v-model="form.bankno"
               type="text"
               maxlength="19"
               placeholder="请输入银行卡号" />
      </div>
    </div>
    <div class="pic">
      <div class="p_title">银行卡影像<span class="red">*</span></div>
      <simple-cropper :initParam="uploadParam"
                      :successCallback="uploadHandle"
                      :successBank="successSeeBank"
                      ref="cropper"
                      class="box">
        <div class="p_pic"
             @click="upload('cropper')">
          <img v-if="form.souhttp != ''"
               :src="ossurl+form.souhttp"
               alt=""
               class="mainpic" />
          <img v-else
               src="@/assets/image/w_yhk.png"
               class="mainpic" />
        </div>
      </simple-cropper>
    </div>
    <div class="btn">
      <div @click="save"
           class="saveBtn">保 存</div>
    </div>
  </div>
</template>
<script>
import { selectYHByEmpno, upYHKMessage } from '@/api/basic'
import { getStorage } from '@/lib/util'
import simpleCropper from './component/simpleCropper'
import { selectBaseBankcode } from '@/api/agent/agent/index'
import { Toast } from 'mand-mobile'
export default {
  components: {
    simpleCropper
  },
  data () {
    return {
      isSelectorShow: false,
      bankList: [],
      ossurl: '',
      user: '',
      form: {
        bankzh: '',
        souhttp: '', // 影像
        bankcode: '',
        bankno: '',
        empno: ''
      },
      uploadParam: {
        file: 'bank',
        type: 'bank',
        fileType: 'recruit', // 其他上传参数
        uploadURL: '/empno/getUpload', // 上传地址
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
        ratio: 5 / 3
      } // 上传头像初始化数据
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.selectBankcode()
  },
  methods: {
    getData () {
      selectYHByEmpno({ empno: this.user.empno }).then(res => {
        this.form = res.data.data
      })
    },
    // 上传图片
    upload (ele) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log(this.$refs[ele].upload())
      }
      this.$refs[ele].upload()
    },
    // 识别银行卡成功回调
    successSeeBank (data) {
      if (data != '' && data != null) {
        Toast.succeed('银行卡信息识别成功')
        this.form.bankno = data.bank_card_number
      } else {
        Toast.info('未能识别出银行卡信息')
      }
    },
    // 上传图片成功回调
    uploadHandle (data) {
      this.form.souhttp = data.path
      Toast.succeed('上传成功')
    },
    save () {
      if (!this.form.souhttp) {
        Toast.failed('请先上传银行卡影像')
        return
      }
      if (!this.form.empno) {
        Toast.failed('请输入工号')
        return
      }
      if (!this.form.bankcode) {
        Toast.failed('请选择开户行')
        return
      }
      if (!this.form.bankzh) {
        Toast.failed('请输入支行')
        return
      }
      if (!this.form.bankno) {
        Toast.failed('请输入银行卡号')
        return
      }
      upYHKMessage(this.form).then(res => {
        Toast.succeed('保存成功')
        this.$router.push('/myCenter')
      })
    },
    changeBank () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ text, value }) {
      this.form.bankcode = value
      this.form.bankname = text
    },
    // 银行卡下拉框
    selectBankcode () {
      selectBaseBankcode().then(res => {
        this.bankList = res.data.data
        this.bankList.forEach((item, index) => {
          this.$set(this.bankList[index], 'value', item.bankid)
          this.$set(this.bankList[index], 'text', item.bankname)
        })
      }).finally(() => {
        this.getData()
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.content {
  background-color: #fff;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 50px 50px;
    border-bottom: 1px solid #efefef;

    span {
      font-size: 0.4rem;
    }

    input {
      overflow: hidden;
      font-size: 40px;
      border: none;
      text-align: right;
      outline: none;
    }
  }

  .pic {
    margin: 50px 50px;
    height: 600px;

    .p_title {
      font-size: 40px;
    }

    .p_pic {
      padding-top: 30px;
    }
  }

  .btn {
    position: fixed;
    bottom: 0;
    width: 100%;

    .saveBtn {
      text-align: center;
      padding: 20px 30px;
      width: 100%;
      background-color: color-primary;
      color: #fff;
      border-radius: 10px;
      font-size: 40px;
    }
  }
}

.red {
  color: red;
}

.mainpic {
  width: 100%;
  height: auto;
}

.change_item {
      overflow: hidden;
      border-bottom: 1px solid #F6F6F6;
      padding: 50px 60px;

      .ci_left {
        display: inline-block;
        width: 30%;
        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }
        .red{
          color:red;
        }
      }
      .ci_right {
        float: right;
        width: 70%;
        text-align: right;

        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }

        input {
          overflow: hidden;
          border: none;
          text-align: right;
          outline: none;
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
          width: 100%;
        }
      }
    }

</style>
